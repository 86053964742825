<template>
  <base-dashboard-section>
    <template #nameSection>{{ translations.my_inbox.Messages }}</template>
    <template #default>
      <p class="text-center m-3" style="line-height: 1.2rem; font-weight: 600">
        {{ translations.myprofile.custom_message_messages ?? "" }}
      </p>
      <div class="container">
        <div class="row justify-content-around justify-content-md-start">
          <div
            class="col-5 col-md-2 messages-selector m-md-2"
            :class="isSelected == 1 ? 'active' : ''"
            @click="select(1)"
          >
            {{ translations.my_inbox.Incoming }}
          </div>
          <div
            class="col-5 col-md-2 messages-selector m-md-2"
            :class="isSelected == 2 ? 'active' : ''"
            @click="select(2)"
          >
            {{ translations.my_inbox.Compose }}
          </div>
        </div>
      </div>
      <hr />
      <div class="container my-5">
        <transition name="fade">
          <div class="row" v-if="isSelected == 1">
            <div class="col-12">
              <div class="container px-3">
                <div class="col-12 mb-3 text-start">
                  <b>{{ translations.my_inbox.Refine_Results }}</b>
                </div>
                <div class="row date-field py-3 d-flex justify-content-center">
                  <div class="col-6">
                    <label class="form-label" for="from_date">{{
                      translations.my_inbox.From
                    }}</label>
                    <input
                      v-model="from_date"
                      class="form-control"
                      id="from_date"
                      type="date"
                      :max="yesterday"
                    />
                  </div>
                  <div class="col-6">
                    <label class="form-label" for="from_date">{{
                      translations.my_inbox.To
                    }}</label>
                    <input
                      v-model="to_date"
                      class="form-control"
                      id="from_date"
                      type="date"
                      :max="today"
                    />
                  </div>
                </div>
                <div
                  class="row my-3 d-flex justify-content-center align-items-end"
                >
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label class="form-label" for="read_unread">{{
                        translations.my_inbox.To
                      }}</label>
                      <select
                        id="read_unread"
                        class="form-control"
                        v-model="type"
                      >
                        <option value="null" selected>
                          {{ translations.my_inbox.All }}
                        </option>
                        <option value="true">
                          {{ translations.my_inbox.Read }}
                        </option>
                        <option value="false">
                          {{ translations.my_inbox.Unread }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label class="form-label" for="received_sent">{{
                        translations.my_inbox.ReceivedSent
                      }}</label>
                      <select
                        id="received_sent"
                        class="form-control"
                        v-model="received_sent"
                      >
                        <option value="null" selected>
                          {{ translations.my_inbox.All }}
                        </option>
                        <option value="false">
                          {{ translations.my_inbox.Incoming }}
                        </option>
                        <option value="true">
                          {{ translations.my_inbox.Outcoming }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-12 col-lg-4">
                    <base-button
                      :caption="translations.my_inbox.Search.toUpperCase()"
                      color="orange"
                      @click="searchMessages"
                    ></base-button>
                  </div>
                </div>
              </div>
              <div class="col-12 p-0 m-0 sdd" v-if="messages.length > 0">
                <card-message
                  :messagesList="messages"
                  :translations="translations"
                ></card-message>
              </div>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="isSelected == 2">
            <div class="row justify-content-center align-items-center">
              <form @submit.prevent>
                <div class="col-12 col-md-4 col-lg-3 my-md-2">
                  <label class="form-label">{{
                    translations.my_inbox.Select_recipient
                  }}</label>
                  <select
                    class="form-control"
                    name="recipient"
                    id="recipient"
                    v-model="selectedRecipient"
                  >
                    <option
                      v-for="recipient in recipients"
                      :key="recipient.code"
                      :value="[recipient.code]"
                    >
                      {{ recipient.name }}
                    </option>
                  </select>
                </div>
                <div class="col-12 mb-md-2">
                  <textarea
                    class="form-control"
                    name="message"
                    id="message"
                    cols="30"
                    rows="10"
                    :maxlength="maxLengthMessage"
                    v-model="message"
                  ></textarea>
                </div>
              </form>
              <div class="col-12 col-md-9 mt-3 mt-md-0">
                <div class="available-text">
                  {{ availableText }}
                </div>
              </div>
              <div class="col-12 col-md-3 mt-3 mt-md-0">
                <base-button
                  :caption="sendCaption"
                  nomargin="true"
                  color="orange"
                  :disabled="sendButtonDisable"
                  @click="sendMessage"
                ></base-button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </base-dashboard-section>
</template>

<script>
import avoidAnotherClick from "@/mixins/avoidDoubleClick.js";
import CardMessage from "@/components/global/ui/CardMessage.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      // SEARCH:
      today: null,
      yesterday: null,
      from_date: null,
      to_date: null,
      type: null,
      received_sent: null,
      trashed: "false",
      // COMPOSE:
      isSelected: "1",
      maxLengthMessage: 2000,
      message: "",
      selectedRecipient: ["02"], // default messenger-group
      subject: "Request from Player dashboard",
    };
  },
  components: {
    CardMessage,
  },
  mixins: [avoidAnotherClick],
  computed: {
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("messages", ["recipients", "resetField", `messages`]),
    ...mapGetters("device", ["isMobile"]),
    availableText() {
      const available = this.maxLengthMessage - this.message.length;
      return `${available} ${
        this.translations.my_inbox.available_characters ?? "characters"
      }`;
    },
    sendButtonDisable() {
      return this.message.length == 0 || this.isButtonClicked;
    },
    sendCaption() {
      if (this.isButtonClicked) {
        return "...";
      } else {
        return this.translations.my_inbox.Send.toUpperCase();
      }
    },
  },
  watch: {
    resetField(value) {
      if (value) {
        this.message = "";
        this.$store.commit("messages/resetMessage", false);
      }
    },
  },
  methods: {
    getDate() {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      let from = new Date();
      from.setDate(from.getDate());
      this.today = new Date().toISOString().slice(0, 10);
      this.yesterday = yesterday.toISOString().slice(0, 10);
      this.to_date = new Date().toISOString().slice(0, 10);
      this.from_date = from.toISOString().slice(0, 10);
    },
    select(v) {
      this.isSelected = v;
    },
    sendMessage() {
      if (this.sendButtonDisable != false) {
        return;
      }
      this.avoidAnotherClick();
      const composedMessage = {
        recipient: this.selectedRecipient,
        subject: this.subject,
        body: this.message,
      };
      this.$store.dispatch("messages/sendMessage", composedMessage);
    },
    searchMessages() {
      let type = null;
      let received = null;
      if (this.type == "true") {
        type = 1;
      } else if (this.type == "false") {
        type = 0;
      }
      if (this.received_sent == "true") {
        received = 1;
      } else if (this.received_sent == "false") {
        received = 0;
      }
      const parameters = {
        from: `?date_from=${this.from_date} 00:00:00`,
        to: `&date_to=${this.to_date} 23:59:59`,
        type: this.type != null && this.type != "null" ? `&read=${type}` : "",
        received: this.received_sent != null && this.received_sent != "null" ? `&sent=${received}` : "",
      };
      const parametersPath = Object.values(parameters).join("");
      this.$store.dispatch("messages/getMessages", parametersPath);
    },
  },
  created() {
    this.getDate();
  },
  mounted() {
    this.$store.dispatch("messages/getRecipients");
  },
};
</script>

<style scoped>
.date-field {
  background: var(--extralight-grey);
  border-radius: 10px;
  transition: var(--short-trans);
  transform: scale(1);
}

.messages-selector {
  padding: 10px 5px;
  background: var(--dirty-white);
  border: 2px solid var(--light-orange);
  border-radius: 15px;
  text-align: center;
  font-weight: 600;
  color: var(--kajot-grey);
  transition: var(--short-trans);
}
.messages-selector:hover {
  background: var(--light-orange);
  transition: var(--short-trans);
  color: var(--dirty-white);
  cursor: pointer;
}
.messages-selector.active {
  background: var(--dark-orange);
  color: var(--dirty-white);
  transition: var(--short-trans);
  border-color: var(--dark-orange);
}
textarea {
  padding: 1.5rem;
  min-width: 100%;
  border-color: var(--extralight-grey);
  resize: none;
}

textarea::-webkit-scrollbar {
  width: 5px;
}

/* Track */
textarea::-webkit-scrollbar-track {
  background: var(--dirty-white);
  border-radius: 2px;
  margin: 15px;
}

/* Handle */
textarea::-webkit-scrollbar-thumb {
  background: var(--light-orange);
  border-radius: 2px;
  transition: var(--short-trans);
}

/* Handle on hover */
textarea::-webkit-scrollbar-thumb:hover {
  background: var(--dark-orange);
  transition: var(--short-trans);
}

.available-text {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}
</style>

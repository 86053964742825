<template>
  <TheHeader />
  <ExitAdminModeButton v-if="isAdminMode" />
  <router-view
    v-slot="slotProps"
    v-if="!isTranslationLoading && !isPlayerLoading && !isCasinoLoading"
  >
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>
  <transition name="fade">
    <base-loading-main
      v-if="isTranslationLoading || isPlayerLoading || isCasinoLoading"
    ></base-loading-main>
  </transition>

  <TheFooter
    v-if="
      !isTranslationLoading && !isPlayerLoading && !isCasinoLoading && !isLoadingBanners
    "
  />
</template>

<script>
// testing header 2
import TheHeader from "./components/global/TheHeader.vue";
import TheFooter from "./components/global/TheFooter.vue";
import ExitAdminModeButton from "./components/global/ExitAdminModeButton.vue";
import BaseLoadingMain from "./components/global/ui/BaseLoadingMain.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
export default {
  components: {
    TheHeader,
    TheFooter,
    BaseLoadingMain,
    ExitAdminModeButton,
  },
  data() {
    return {
      timeInactive: 0,
      intervalInactive: null,
      isAdminMode: false,
      isLogged: localStorage.getItem("X19fYWNjZXNzX19f") ?? false,
    };
  },
  computed: {
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("player", ["isPlayerLoading", "playerInfo"]),
    ...mapGetters("casino", ["isCasinoLoading", "allSkinProviders"]),
    ...mapGetters("skinBanners", ["casino_banners", "isLoadingBanners"]),
    ...mapGetters("skinSettings", ["maintenance"]),
    isLoading() {
      return (
        this.isTranslationLoading ||
        this.isPlayerLoading ||
        this.isCasinoLoading ||
        this.isLoadingBanners
      );
    },
  },
  watch: {
    isPlayerLoading() {
      if (!this.isTranslationLoading) this.termsAndConditions();

      this.inactiveTime();
    },
    isTranslationLoading() {
      if (!this.isPlayerLoading) this.termsAndConditions();
    },
    $route() {
      this.checkIsImportedPlayer();
    },
  },
  beforeUnmount: function () {
    clearInterval(this.intervalInactive);
  },
  methods: {
    checkAdminMode() {
      const adminNickname = sessionStorage.getItem("adminNickname");
      const adminPassword = sessionStorage.getItem("adminPassword");
      const vanillaTranslations = sessionStorage.getItem("vanilla-translations");
      this.isAdminMode = adminNickname && adminPassword && vanillaTranslations;
    },
    checkIsImportedPlayer() {
      let isCrypt = this.playerInfo?.is_crypt;
      if (this.isLogged && isCrypt == false) {
        this.$router.push({ name: "ChangePasswordImportPlayer" });
      } else {
        return;
      }
    },
    inactiveTime() {
      let isLogged = localStorage.getItem("X19fYWNjZXNzX19f") != null;
      if (isLogged) {
        this.intervalInactive = setInterval(
          function () {
            this.timeInactive++;
            if (this.timeInactive >= 30) this.$store.dispatch("player/logout");
          }.bind(this),
          60000
        ); //1 minutes

        addEventListener("mousemove", () => {
          this.timeInactive = 0;
        });
        addEventListener("keypress", () => {
          this.timeInactive = 0;
        });
      }
    },
    setReferralCode() {
 
      let isReferralLink = !!this.$route.query.btag;
      if (isReferralLink) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);
        VueCookies.set("btag", this.$route.query.btag, expirationDate.toUTCString());
      }
    },
    termsAndConditions() {
      if (
        Object.prototype.hasOwnProperty.call(this.playerInfo, "marketing") &&
        !this.playerInfo.marketing.terms_and_conditions &&
        this.$route.name != "terms-and-conditions"
      ) {
        Swal.fire({
          title: this.translations.dashboard_menu.Attention ?? "Attention",
          html:
            this.translations.body.terms_and_conditions ??
            "We have made changes to our Terms and Conditions. In order to continue to use Kajot-Casino, please confirm your acknowledgment.</p><p><a href='/terms-and-conditions' target='_blank'>Terms and Conditions</a>",
          icon: "warning",
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: this.translations.dashboard_menu.YesWant ?? "Yes, I Want",
          cancelButtonText:
            this.translations.dashboard_menu.NoThink ?? "No, I need to think about it",
        }).then((result) => {
          let body = { terms_and_conditions: true };
          let playerId = this.playerInfo.user_id;
          if (result.isConfirmed) {
            this.$store
              .dispatch("registration/updatePlayer", {
                body,
                playerId,
              })
              .then(() => {
                location.reload();
              });
          }
        });
      }
    },
  },
  async created() {
    let languagesSkins = ["en", "cs", "de", "sk", "hu", "pl"];
    if (languagesSkins.includes(this.$route.params.lang))
      localStorage.setItem("language", this.$route.params.lang);

    // MAKE A.F.API
    this.$store.dispatch("api/createAuth");
    // CALL CLIENT IP
    this.$store.dispatch("api/getClientIp");
    // CHECK IF TOKEN EXIST
    await this.$store.dispatch("player/setTokenOnLoad").then(() => {
      this.checkIsImportedPlayer();
      if (this.playerInfo) {
        let enableSocket = process.env.VUE_APP_WEBSOCKET_ENABLE ?? false;
        if (enableSocket === "true") {
          this.$store.dispatch("player/websocketLogin"); // Connect to the websocket only when the player data is fetched
        }
      }
    });

    // VERIFING DEVICE
    this.$store.commit("device/runDeviceDetector");
    // CALL SKIN TRANSLATIONS
    // this.$store.dispatch("translations/getSkinLanguages");
    // CALL SKIN SETTINGS
    this.$store.dispatch("skinSettings/callSkinSettings");
    this.$store.dispatch("device/getDeviceDimensions");
    // CALL SKIN IN MAINTENANCE
    this.$store.dispatch("skinSettings/callSkinInMaintenance").then(() => {
      if (this.maintenance) this.$router.push({ name: "MaintenancePage" });
    });
    // CALL GAMES
    // this.$store.dispatch("casino/callSkinSections");

    await this.$store
      .dispatch("translations/getSkinLanguages")
      .then(() => {
        return this.$store.dispatch("casino/callSkinSections");
      })
      .catch((error) => {
        console.error("error", error);
      });
    this.checkAdminMode();
    // this.$store.dispatch("casino/callSkinProviders");
    this.setReferralCode();
  },
  mounted() {
    // CALL LISTENER FOR WIDTH DEVICE
    window.addEventListener("resize", () => {
      this.$store.dispatch("device/getDeviceDimensions");
    });
  },
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-icons";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  /* COLORS */
  --dark-grey: rgb(71, 66, 64);
  --darker-grey: rgb(33, 37, 41);
  --light-grey: rgb(97, 94, 93);
  --kajot-grey: rgb(43, 41, 39);
  --extralight-grey: rgb(151 146 145 / 20%);
  --extralight2-grey: rgb(225 224 224 / 20%);
  --dark-orange: rgb(219, 81, 18);
  --light-orange: rgba(234, 108, 50, 0.957);
  --extralight-orange: rgba(234, 108, 50, 0.486);
  --dark-red: rgba(220, 53, 69, 1);
  --light-green: rgb(65, 214, 0);
  --dark-green: rgb(55, 181, 0);
  --darker-green: rgb(3, 103, 18);
  --dark-yellow: rgb(202, 187, 23);
  --dirty-white: rgb(250, 250, 250);

  /* TRANSITIONS */
  --short-trans: all 0.25s ease-in-out;
  --medium-trans: all 0.5s ease-in-out;
  --strange-trans: all 0.25s cubic-bezier(1, 0.86, 0.49, 2.84);

  /* BOX SHADOWS */
  --light-shadow: 0 1rem 2rem rgb(0 0 0 / 10%);
  --orange-shadow: 0 1rem 2rem rgb(234 108 50 / 20%);
  --dark-shadow: -1rem 1rem 10rem rgb(0 0 0 / 80%);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: rgb(90, 90, 90);
  text-decoration: none;
  text-align: justify;
}
body {
  background: var(--dirty-white);
  margin: 0 auto;
  max-width: 1920px;
  float: none;
}
p.warning {
  margin-top: -1rem;
  font-size: 0.65rem;
  color: var(--dark-red);
}
.text-error {
  font-size: 2rem;
  color: var(--dark-red);
}
.text-success {
  font-size: 2rem;
  color: var(--darker-green);
}
.text-error-small {
  font-size: 1rem;
  font-weight: 600;
  color: var(--dark-red);
}
.text-success-small {
  font-size: 1rem;
  font-weight: 600;
  color: var(--darker-green);
}
.form-control:focus {
  border-color: var(--light-orange);
  box-shadow: 0px 0px 0.5rem 0.01rem var(--dark-orange);
}
select {
  cursor: pointer;
}
a {
  color: var(--dark-orange);
  text-decoration: none;
}
a:hover {
  color: var(--dark-orange);
  text-decoration: underline;
}
.form-check-input:checked {
  background-color: var(--dark-orange);
  border-color: var(--dark-orange);
}
.form-check-input:focus {
  box-shadow: 0px 0px 0.5rem 0.01rem var(--dark-orange);
}
button.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

/* LOAD MORE BUTTON - START */
.loadMoreButton {
  left: 30%;
  top: -10vh;
  position: absolute;
  z-index: 59;
  right: 30%;
  width: auto !important;
  min-width: 40%;
}
.load-more::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 17vh;
  z-index: 59;
  top: -20vh;
  box-shadow: 0 4vh 0.08vh rgb(250 250 250);
  filter: blur(6px);
  background: rgb(164, 164, 164);
  background: linear-gradient(
    0deg,
    rgb(250 250 250) 0%,
    rgb(246 246 246) 37%,
    rgb(246 246 246 / 85%) 100%
  );
}
/* LOAD MORE BUTTON - END */

/* VUE TRANSITION - START*/
.route-enter-from {
  opacity: 0;
  transform: translateY(-20px);
}

.route-enter-active {
  transition: all 0.5s ease-in-out;
}

.route-leave-active {
  transition: all 0.5s ease-in-out;
}

.router-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.route-leave-from {
  opacity: 1;
  transform: translateY(0px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: var(--short-trans);
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-up-enter-from,
.fade-up-leave-to {
  opacity: 0;
  transform: translateY(-150px);
}
.fade-up-enter-active,
.fade-up-leave-active {
  transition: var(--medium-trans);
}
.fade-up-enter-to,
.fade-up-leave-from {
  opacity: 1;
  transform: translateY(0);
}
.error-message-enter-from,
.error-message-leave-to {
  opacity: 0;
}
.error-message-enter-active,
.error-message-leave-active {
  transition: var(--short-trans);
}
.error-message-enter-to,
.error-message-leave-from {
  opacity: 1;
}
.form-enter-to {
  transform: scaleY(1);
  opacity: 1;
}
.form-enter-active {
  transition: var(--medium-trans);
}
.form-enter-from {
  transform: scaleY(-0.01);
  opacity: 0;
}
.form-leave-to {
  transform: scaleY(-0.01);
  opacity: 0;
}
.form-leave-active {
  transition: var(--short-trans);
}
.form-leave-from {
  transform: scaleY(1);
  opacity: 1;
}
/* VUE TRANSITION - END*/

.accordion-body {
  padding: 5px !important;
}

@media (max-width: 1199px) {
  .home {
    position: relative;
    top: 54px;
  }
}
</style>

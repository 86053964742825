// import config from '/config.json';
import VueCookies from 'vue-cookies';
import { io } from 'socket.io-client';

export default {
	callingAboutUser({ dispatch }) {
		dispatch('syncMessage');
		let promises = [
			// dispatch('callUserDocuments'),
			// dispatch('payments/callPaymentMethods', {}, { root: true }),
			// dispatch('payments/getWithdrawPaymentMethods', {}, { root: true }),
			// dispatch('payments/getDepositPaymentMethods', {}, { root: true }),
			dispatch('callUserInformation'),
			dispatch('callPlayerBalance', true)

		];
		return Promise.all(promises).then(() => {
			/*	let enableSocket = process.env.VUE_APP_WEBSOCKET_ENABLE ?? false;
				if (enableSocket === 'true')
					dispatch('websocketLogin');	// Connect to the websocket only when the player data is fetched */
		});
	},
	async websocketLogin({ dispatch, getters, commit }) {
		/* window.ws.on('open', () => {

			window.ws.send('login', getters.tokenAuth);

			window.ws.on('login', (data) => {
				window.ws.clientId = data.clientId;
				const interval = 10000;

				setTimeout(() => dispatch('callMessage'), interval);

			});
			window.ws.on('message', (data) => {

				dispatch('alert/readOne_message', [data], {
					root: true,
				});
			});
			window.ws.on('campaign', (data) => {
				dispatch('setCampaign', data);
			});
		});
		window.ws.on('error', () => {
			console.log("failed connection to wss");
			// if websocket connection fails
			dispatch('syncMessage');
		});
		config.websocketUrl = process.env.VUE_APP_WEBSOCKETURL;
		window.ws.connect(config); */

		let endpoint = process.env.VUE_APP_WEBSOCKETURL ?? "ws://localhost:4000";
		const socket = io(endpoint);
		socket.on('connect_error', (error) => {
			console.error(`Socket connection error: ${error.message}`);
			commit('setIsSocketOn', false)
			// dispatch('syncMessage');
			return;
		});

		socket.on('connect', () => {

			commit('setIsSocketOn', true)
			// Store the client socket ID by playerId and join a room by skin refID (if it doesn't exist already) on the webSocket server
			socket.emit('create', { playerId: getters.playerInfo.id, refId: process.env.VUE_APP_REFID });
			const interval = 10000;

			setTimeout(() => {
				if (getters.isAuthenticated) {
					dispatch('callMessage')
				}
			}, interval);
		});

		socket.on('message', (data) => {
			dispatch('alert/readOne_message', [data], {
				root: true,
			});
		});

		socket.on('campaign', (data) => {

			dispatch('setCampaign', data);
		});
	},
	// async login({ state, getters, dispatch }, payload) {
	// 	let body = {
	// 		username: payload.nickname,
	// 		password: payload.password,
	// 		ip_address: payload.ipAddress,
	// 		email_verified: true
	// 	};
	// 	const responseData = await getters.axios
	// 		.post(getters.endpoint + state.path.login, body, getters.auth)
	// 		.then((response) => {
	// 			return response.data;
	// 		})
	// 		.catch((err) => err);
	// 	if (Object.prototype.hasOwnProperty.call(responseData, 'token')) {
	// 		state.isLoading = true;
	// 		dispatch('encodeLogin', responseData.token);
	// 		VueCookies.remove("mgaCounter");
	// 		location.reload();
	// 	} else {
	// 		dispatch('alert/logAlert', responseData, { root: true });
	// 	}
	// },
	async login({ state, getters, dispatch }, payload) {
		let body = {
			username: payload.nickname,
			password: payload.password,
			ip_address: payload.ipAddress,
			// email_verified: true
		};

		const responseData = await getters.axios
			.post(getters.endpoint + state.path.login + '?checkHash=1', body, getters.auth)
			.then((response) => {
				return response.data;
			})
			.catch((err) => err);


		if (Object.prototype.hasOwnProperty.call(responseData, 'token')) {
			state.isLoading = true;
			dispatch('encodeLogin', responseData.token);
			VueCookies.remove("mgaCounter");

			// check variabile session
			let savedPath = sessionStorage.getItem('savedPath');
			let livePath = sessionStorage.getItem('temp-route')
			if (savedPath) {
				sessionStorage.removeItem('savedPath');
				sessionStorage.removeItem('temp-route')
				window.location.href = savedPath;
			} else if (livePath) {
				sessionStorage.removeItem('temp-route');
				sessionStorage.removeItem('savedPath');
				window.location.href = livePath;
			} else {
				location.reload();
			}
		} else {
			dispatch('alert/logAlert', responseData, { root: true });
		}
	},

	async logout({ commit, state, getters, dispatch }) {
		VueCookies.remove("mgaCounter");
		let config = {
			path: state.path.logout,
		};
		const responseData = await getters.axios
			.delete(getters.endpoint + config.path, getters.tokenAuth)
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				console.log(err);
				return;
			});

		commit('logoutPlayer', responseData);
		dispatch('alert/logAlert', responseData, { root: true });
	},
	async setTokenOnLoad({ state, getters, commit, dispatch }) {
		state.isLoading = true;
		dispatch('callPlayerBalance', false);
		commit('setTokenOnLoad');
		if (getters.isAuthenticated) {
			await dispatch('callingAboutUser');
		} else {
			commit('playerLoadingCompleted');
		}
	},
	encodeLogin({ commit }, payload) {
		const access = window.btoa(encodeURIComponent('___access___'));
		const codecToken = window.btoa(encodeURIComponent(payload));
		commit('loginPlayer', { access, codecToken });
	},
	async callUserInformation({ commit, state, getters }) {
		const responseData = await getters.axios
			.get(getters.endpoint + state.path.information, getters.tokenAuth)
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				console.log(err);
				commit('logoutPlayer', '');
				return;
			});
		commit('setUserInformation', responseData);
	},
	async callPlayerBalance({ state, getters, commit }, status) {
		const config = {
			path: state.path.balance,
		};
		const makeCallBalance = async () => {
			const responseData = await getters.axios
				.get(getters.endpoint + config.path, getters.tokenAuth)
				.then((response) => response.data.data)
				.catch((err) => err);
			commit('setPlayerBalance', responseData);
		};
		const startToCallBalance = setInterval(makeCallBalance, 5000);
		if (!status) {
			clearInterval(startToCallBalance);
		}
	},
	async callLastStatistic({ commit, state, getters }, payload) {
		let type = payload.type;
		let toDo = payload.to_activate;
		let config = {
			path: state.path.lastStatistic + '?type=' + type + '&to_activate=' + toDo,
		};
		const responseData = await getters.axios
			.get(getters.endpoint + config.path, getters.tokenAuth)
			.then((response) => {

				return response.data;
			})
			.catch((err) => {
				console.log(err);
				return;
			});
		if (type == 'limits')
			commit('setStatisticLimits', responseData);
		else
			commit('setStatisticExclusions', responseData);

	},
	async callAccessTokensKyc({ commit, state, getters }, level) {
		let config = {
			path: state.path.check_token_kyc + level,
		};
		const responseData = await getters.axios
			.get(getters.endpoint + config.path, getters.tokenAuth)
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				console.log(err);
				return;
			});
		commit('setKycToken', responseData);
	},
	async callUserDocuments({ commit, state, getters }) {
		let config = {
			path: state.path.allDocuments,
		};

		const responseData = await getters.axios
			.get(getters.endpoint + config.path, getters.tokenAuth)
			.then((response) => response.data.data)
			.catch((err) => {
				console.log(err);
				return;
			});
		commit('setUserDocuments', responseData);
	},
	async getSurvey({ commit, state, getters }, type) {
		let currency = getters.playerInfo.currency.code;
		let config = {
			path: state.path.survey + 'last?type=' + type + "&currency=" + currency,
		};

		const responseData = await getters.axios
			.get(getters.endpoint + config.path, getters.tokenAuth)
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				console.log(err);
				return;
			});

		commit('setUserSurvey', responseData);
	},
	async getAmlLevel({ commit, state, getters }) {
		let config = {
			path: state.path.amlLevel,
		};

		const responseData = await getters.axios
			.get(getters.endpoint + config.path, getters.tokenAuth)
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				console.log(err);
				return;
			});

		commit('setUserAml', responseData);
	},
	async surveyAnswers({ commit, state, getters }, answers) {
		//per adesso passiamo fisso inglese
		let config = {
			path:
				state.path.survey + answers.survey_id + '/answers/' + answers.language,
		};

		const responseData = await getters.axios
			.post(getters.endpoint + config.path, answers, getters.tokenAuth)
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				console.log(err);
				return;
			});
		location.reload();
		commit('setSurveyAnswers', responseData);
	},
	async callMoveLevelKyc({ commit, state, getters }, level) {
		let config = {
			path: 'kyc/players/' + state.playerInfo.user_id + '/changeLevel',
		};
		let body = { level: level };

		await getters.axios
			.post(getters.endpoint + config.path, body, getters.tokenAuth)
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				console.log(commit);
				console.log(err);
				return;
			});

		location.reload();
	},
	async supportNotification({ commit, getters }, payload) {
		let config = {
			path: 'notifications/messages/custom_email'
		};
		let body = { emails: payload.emails, body: payload.content_body, subject: payload.subject, ref_id: payload.ref_id };
		await getters.axios
			.post(getters.endpoint + config.path, body, getters.tokenAuth)
			.then((response) => {
				return response.data;
			})
			.catch((err) => {
				console.log(commit);
				console.log(err);
				return;
			});


	},
	async playerSelfExclusion({ commit, state, getters }, payload) {

		let time = payload.time;

		let activated = payload.activated;
		if (payload.status_reason)
			state.status_reason = payload.status_reason;

		let min = state.playerInfo.status_expires_at
			? state.playerInfo.status_expires_at.slice(0, 10)
			: new Date().toISOString().slice(0, 10);

		 
 
		let config = {
			path: state.path.edit_information,
		};

		let body = {
			// status: state.status_inactive,
			statistic: true,
			status_reason: state.status_reason,
			status_expires_at: time,
		};
		 
		let date = new Date();
		if (time.includes('2999-12-31')) 
		{
			date.setDate(date.getDate() + 7);
			body.status_statistic = 'authorized';
			body.expires_at_forever = true;
			body.activated_at = date.toISOString().substring(0, 19).replace("T", " ");
		}
		else if (activated && min != '2999-12-31') {
			date.setDate(date.getDate() + 1);
			body.activated_at = date.toISOString().substring(0, 19).replace("T", " ");
		}
		 
		const responseData = await getters.axios
			.put(getters.endpoint + config.path, body, getters.tokenAuth)
			.then((response) => {
				return response;
			})
			.catch((err) => {
				console.log(err);
				return;
			});

		commit('setExclusion', { responseData, time });
	},
	async editPlayerLimits({ commit, state, getters }, payload) {
		let config = {
			path: state.path.edit_information,
		};

		let monthlyLimit = parseFloat(payload.max_daily_deposit) * 31;

		let body = {
			max_daily_deposit: parseFloat(payload.max_daily_deposit),
			max_weekly_deposit: monthlyLimit,
			max_monthly_deposit: monthlyLimit,
			max_daily_bet: parseFloat(payload.max_daily_bet),
			max_daily_loss: parseFloat(payload.max_daily_loss),
		};

		let type = ['max_daily_deposit', 'max_daily_bet', 'max_daily_loss'];
		let i, old, newVal, field, activated;
		state.isMajorLimit = false;
		for (i = 0; i < type.length; i++) {
			field = type[i];
			if (field != 'max_daily_deposit')
				old = state.playerInfo.betLimits[field] != null ? state.playerInfo.betLimits[field] : 0;
			else
				old = state.playerInfo.limits[field] ? state.playerInfo.limits[field] : 0;


			newVal = payload[field];
			activated = false;
			body.field = field;
			delete body.activated_at;

			if (parseFloat(newVal) > parseFloat(old) && parseFloat(old) != 0)
				activated = true;


			if (activated) {
				state.isMajorLimit = true;
				let date = new Date();
				date.setMinutes(date.getMinutes() + 1);
				date.setSeconds(0);
				date.setDate(date.getDate() + 1);
				body.activated_at = date.toISOString().substring(0, 19).replace("T", " ");
			}


			if (newVal != old) {

				await getters.axios
					.put(getters.endpoint + config.path, body, getters.tokenAuth)
					.then((response) => {
						return response;
					})
					.catch((err) => err.response);
			}

		}

		commit('setPlayerLimits');
	},
	async changePlayerPassword({ commit, state, getters }, payload) {
		let config = {
			path: state.path.edit_information,
		};
		let body = {
			password_current: payload.current,
			password: payload.new,
			password_confirmation: payload.confirm,
		};
		const responseData = await getters.axios
			.put(getters.endpoint + config.path, body, getters.tokenAuth)
			.then((response) => {
				return response;
			})
			.catch((err) => err.response);
		commit('changePlayerPassword', responseData);
	},
	async playerRemoveChanges({ commit, state, getters }, id) {
		let config = {
			path: state.path.removeChanges + "" + id,
		};

		await getters.axios
			.delete(getters.endpoint + config.path, getters.tokenAuth)
			.then((response) => response)
			.catch((err) => err);

		return commit;
	},
	async checkTempToken({ state, getters, commit, dispatch }, payload) {
		state.isCheckingToken = true;
		let config = {
			path: state.path.check_token_status,
		};
		const responseData = await getters.axios
			.get(getters.endpoint + config.path + payload, getters.auth)
			.then((response) => response.data)
			.catch((err) => err);
		commit('checkTempToken', responseData.is_valid);
		if (responseData.is_valid) {
			dispatch('setTempToken', payload);
		}

		return responseData;
	},
	setTempToken(context, payload) {
		context.commit('setTempToken', payload);
	},
	async changeForgotPassword({ commit, state, getters }, payload) {
		let config = {
			path: state.path.reset_password,
		};
		let body = {
			email: payload.email,
			password: payload.new,
			password_confirmation: payload.confirm,
			token: getters.temp_token,
		};
		const responseData = await getters.axios
			.put(getters.endpoint + config.path, body, getters.auth)
			.then((response) => {
				return response.status;
			})
			.catch((err) => err.response);
		// commit('changeForgotPassword', responseData);
		let alertText = {
			message: getters.translations.login.passwordChanged,
			path: '/login',
		};
		if (responseData != 204) {
			alertText.message = getters.translations.login.errorContactSupport;
		}
		commit('alert/simple_alert', alertText, { root: true });
	},
	setSelectedDocumentToUpload({ state }, name) {
		if (name == undefined) {
			state.selectedDocumentToUpload.value =
				state.selectedDocumentToUpload.default;
			return;
		}
		state.selectedDocumentToUpload.value = name;
	},

	async sendDocuments({ state, getters, commit }, { formData, contentType }) {
		const config = {
			path: state.path.documents,
		};
		const { formContent } = contentType;
		const headers = {
			authorization: getters.auth.headers.authorization,
			'user-token': getters.token,
			formContent,
		};
		const responseData = await getters.axios
			.post(getters.endpoint + config.path, formData, { headers })
			.then((response) => response)
			.catch((err) => err);
		let message = null;
		if (Object.prototype.hasOwnProperty.call(responseData, 'response')) {
			const obj = responseData.response.data;
			message = obj[Object.keys(obj)[0]];
		} else {
			message = 'Document uploaded';
		}
		commit('alert/simple_alert', { message }, { root: true });
		commit('sendDocuments', responseData);
	},
	syncMessage({ dispatch }) {
		setInterval(function () { dispatch('callMessage') }, 5000);
	},
	setCampaign({ state }, payload) {
		state.playerCampaign = payload;
	},
	async callMessage({ state, getters, dispatch }) {
		const pathSuffix = 'readone';
		let openSweet = document.getElementsByClassName('swal2-container').length; //fare chiamata solo se non c'è nessun popup aperto

		if (!openSweet) {
			// lock popup if is displayed another one
			//PROBABILMENTE NON POSSO VEDERE IN REALTIME POPUP NUOVI
			const responseData = await getters.axios.get(
				getters.endpoint + state.path.messages + pathSuffix,
				getters.tokenAuth
			);
			if (Object.prototype.hasOwnProperty.call(responseData, 'data')) {
				if (responseData.data.length > 0) {
					dispatch('alert/readOne_message', responseData.data, {
						root: true,
					});
				}
			}
		}

	},
	async messageHasBeenRed({ state, getters, commit }, payload) {
		const responseData = await getters.axios
			.get(getters.endpoint + state.path.messages + payload, getters.tokenAuth)
			.then((response) => response)
			.catch((err) => err);
		commit('messageHasBeenRed', responseData);
	},
	async checkOptOutToken({ state, getters, commit }, payload) {
		state.isCheckingOptOutToken = true;
		let config = {
			path: 'notifications/newsletter/token/opt-out',
		};

		const responseData = await getters.axios
			.get(getters.endpoint + config.path, {
				params: payload
			})
			.then((response) => {
				state.optOutToken = response.data.is_valid;
				return response.data.is_valid;
			})
			.catch((err) => err.response.data.is_valid);
		commit('checkOptOutToken', responseData);
	},
	async newsletterOptOut({ state, getters, commit }, payload) {
		console.log(state);
		let config = {
			path: 'notifications/newsletter/opt-out',
		};

		const responseData = await getters.axios
			.post(getters.endpoint + config.path, payload)
			.then((response) => {
				return response.data.message;
			})
			.catch((err) => err.response.data.message);
		commit('newsletterOptOut', responseData);
	},
};
